// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const hi = {
  conversationEndedThanks: 'हमारे साथ चैट करने के लिए धन्यवाद!',
  conversationEndedMoreQuestions:
    'यदि आपके कोई और प्रश्न हैं, तो फिर से संपर्क करने में संकोच न करें।',
  conversationEndedNewChat: 'नई चैट शुरू करें',
  headerLiveChat: 'लाइव चैट',
  'language.en': 'अंग्रेजी',
  'language.ar': 'अरबी',
  'language.hy': 'अर्मेनियाई',
  'language.zh': 'चीनी (सरलीकृत)',
  'language.tl': 'फिलिपिनो/तागालोग',
  'language.hi': 'हिंदी',
  'language.hmn': 'हमोंग',
  'language.ja': 'जापानी',
  'language.km': 'खमेर',
  'language.ko': 'कोरियाई',
  'language.lo': 'लाओ',
  'language.fa': 'फ़ारसी/फ़ारसी',
  'language.pa': 'पंजाबी',
  'language.ru': 'रूसी',
  'language.es': 'स्पेनिश',
  'language.th': 'थाई',
  'language.uk': 'यूक्रेनियन',
  'language.vi': 'वियतनामी',
  'language.other': 'अन्य',
  loadingAuthorizing: 'अधिकृत किया जा रहा है',
  messageBubbleYouSentAt: 'आपने यहां भेजा',
  messageBubbleSentAt: '{{name}} पर भेजा गया',
  messageBubbleMediaMessagesNotSupported: 'मीडिया संदेश समर्थित नहीं हैं',
  messageBubbleRead: 'पढ़ें',
  messageInputInstructions: 'अपना संदेश टाइप करें',
  messageInputSendMessage: 'सन्देश भेजो',
  messageListAgent: 'एजेंट',
  messageListChatStarted: 'चैट शुरू हुई',
  messageListIsTyping: '{{name}} टाइप कर रहा है...',
  messageListLoading: 'लोड हो रहा',
  messageListNew: 'नया',
  messageListToday: 'टुडे',
  messageListYesterday: 'कल',
  preEngagementHiThere: 'नमस्ते!',
  preEngagementIntro:
    'यह Soluna संपर्क केंद्र और टेलीकोचिंग वेब-चैट है। हम रोज़ाना आपके सवालों के जवाब देने के लिए उपलब्ध रहेंगे',
  preEngagementIntroWithHours:
    '$t(प्री-एंगेजमेंट इंट्रो), सुबह 10 बजे से रात 10 बजे के बीच {{timezoneAbbrev}}।',
  preEngagementName: 'नाम',
  preEngagementNameInstructions: 'कृपया अपना नाम दर्ज़ करें',
  preEngagementEmail: 'ईमेल पता',
  preEngagementEmailInstructions: 'कृपया अपना ईमेल पता दर्ज करें',
  preEngagementLanguage: 'भाषा',
  preEngagementHowCanWeHelp: 'हम आपकी किस तरह मदद कर सकते हैं?',
  preEngagementInstructions: 'एक प्रश्न पूछें',
  preEngagementStartChat: 'चैट शुरू करें',
  preEngagementChatClosed: 'चैट बंद हो गई है।',
  preEngagementChatClosedExplanation:
    'दुर्भाग्य से Soluna संपर्क केंद्र और टेलीकोचिंग वेब-चैट फिलहाल बंद है। हम रोज़ाना आपके सवालों के जवाब देने के लिए उपलब्ध रहेंगे',
  preEngagementChatClosedExplanationWithHours:
    '$t(प्री-एंगेजमेंट चैट क्लोज्ड एक्सप्लेनेशन), सुबह 10 बजे से रात 10 बजे के बीच {{timezoneAbbrev}}।',
  transcriptDownload: 'डाउनलोड',
  transcriptGeneratingTranscript: 'ट्रांसक्रिप्ट तैयार कर रहा है...',
  transcriptDownloading: 'ट्रांसक्रिप्ट डाउनलोड हो रहा है...',
  transcriptSendToEmail: 'मेरे ईमेल पर भेजें',
  transcriptSending: 'ट्रांसक्रिप्ट भेजा जा रहा है...',
  transcriptDoYouWantATranscript:
    'क्या आप हमारी चैट का ट्रांसक्रिप्ट चाहते हैं?',
}
