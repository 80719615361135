import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

// this logic is simialr to the `check-business-hours` serverless function
// so may be an opportunity to pull this into a shared function moving forward
const isWebChatOpen = () => {
  const webchatHoursStart = Number(
    process.env.CONTACT_CENTER_BUSINESS_HOURS_START
  )
  const webchatHoursEnd = Number(process.env.CONTACT_CENTER_BUSINESS_HOURS_END)
  const timezone = process.env.CONTACT_CENTER_TIMEZONE

  const currentDate = dayjs.tz(Date.now(), timezone)
  const currentHourInDay = Number(currentDate.format('H'))
  const currentMinInHour = Number(currentDate.format('m'))

  // close incoming chats 30 mins before business hours end
  const isHalfPastClosingHour =
    currentHourInDay === webchatHoursEnd && currentMinInHour < 30
  return (
    currentHourInDay >= webchatHoursStart &&
    (currentHourInDay < webchatHoursEnd || isHalfPastClosingHour)
  )
}

export { isWebChatOpen }
