// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const ja = {
  conversationEndedThanks: '私たちとチャットしてくれてありがとう!',
  conversationEndedMoreQuestions:
    'ご不明な点がございましたら、お気軽にお問い合わせください。',
  conversationEndedNewChat: '新しいチャットを開始する',
  headerLiveChat: 'ライブチャット',
  'language.en': '英語',
  'language.ar': 'アラビア語',
  'language.hy': 'アルメニア語',
  'language.zh': '中国語(簡体字)',
  'language.tl': 'フィリピノ語/タガログ語',
  'language.hi': 'ヒンディー語',
  'language.hmn': 'モン族',
  'language.ja': '日本語',
  'language.km': 'クメール語',
  'language.ko': '韓国語',
  'language.lo': 'ラーオ語',
  'language.fa': 'ペルシア語/ペルシア語',
  'language.pa': 'パンジャブ語',
  'language.ru': 'ロシア語',
  'language.es': 'スペイン語',
  'language.th': 'タイ語',
  'language.uk': 'ウクライナ',
  'language.vi': 'ベトナム語',
  'language.other': 'その他',
  loadingAuthorizing: '承認',
  messageBubbleYouSentAt: 'で送信しました',
  messageBubbleSentAt: '{{name}} 送信先',
  messageBubbleMediaMessagesNotSupported:
    'メディア メッセージはサポートされていません',
  messageBubbleRead: '読む',
  messageInputInstructions: 'メッセージを入力します',
  messageInputSendMessage: 'メッセージを送る',
  messageListAgent: 'エージェント',
  messageListChatStarted: 'チャット開始',
  messageListIsTyping: '{{name}} 入力しています...',
  messageListLoading: '積載',
  messageListNew: '新機能',
  messageListToday: '今日',
  messageListYesterday: '昨日',
  preEngagementHiThere: 'こんにちは！',
  preEngagementIntro:
    'これは、Soluna コンタクト センターおよびテレコーチング Web チャットです。 お客様のご質問に毎日お答えします',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro)、午前10時から午後10時まで {{timezoneAbbrev}}。',
  preEngagementName: '名前',
  preEngagementNameInstructions: 'お名前をご入力ください',
  preEngagementEmail: 'アドレス',
  preEngagementEmailInstructions: 'メールアドレスを入力してください',
  preEngagementLanguage: '言語',
  preEngagementHowCanWeHelp: 'どのようなご用件でしょうか?',
  preEngagementInstructions: '質問する',
  preEngagementStartChat: 'チャットを開始',
  preEngagementChatClosed: 'チャットは終了しました。',
  preEngagementChatClosedExplanation:
    '残念ながら、Soluna コンタクト センターとテレコーチング Web チャットは現在閉鎖されています。 お客様のご質問に毎日お答えします',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation)、午前 10 時から午後 10 時まで {{timezoneAbbrev}}.',
  transcriptDownload: 'ダウンロード',
  transcriptGeneratingTranscript: 'トランスクリプトを生成しています...',
  transcriptDownloading: 'トランスクリプトをダウンロードしています...',
  transcriptSendToEmail: '私のメールに送る',
  transcriptSending: 'トランスクリプトを送信しています...',
  transcriptDoYouWantATranscript: 'チャットのトランスクリプトが必要ですか?',
}
