// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

import { en } from './en'
import { ar } from './ar'
import { es } from './es'
import { fa } from './fa'
import { hi } from './hi'
import { hmn } from './hmn'
import { hy } from './hy'
import { ja } from './ja'
import { km } from './km'
import { ko } from './ko'
import { lo } from './lo'
import { pa } from './pa'
import { ru } from './ru'
import { th } from './th'
import { tl } from './tl'
import { uk } from './uk'
import { vi } from './vi'
import { zh } from './zh'
export const translations = {
  en,
  ar,
  es,
  fa,
  hi,
  hmn,
  hy,
  ja,
  km,
  ko,
  lo,
  pa,
  ru,
  th,
  tl,
  uk,
  vi,
  zh,
}
