import { useEffect } from 'react'
import * as Sentry from '@sentry/react'

export const SetupSentry = () => {
  useEffect(() => {
    if (process.env.DEPLOYMENT_ENV) {
      Sentry.init({
        dsn: 'https://f320b475966a11f65d3b410d5dfdef28@o367623.ingest.sentry.io/4506552189911040',
        environment: process.env.DEPLOYMENT_ENV,
        tracesSampleRate: 0.1,
      })
    }
  }, [])

  return null
}
