import log from 'loglevel'

export const getDaylightSavingsAwareTz = (
  { timeZone = 'America/Los_Angeles' },
  now = new Date()
) => {
  try {
    const formatter = new Intl.DateTimeFormat('en', {
      timeZone,
      timeZoneName: 'short',
    })

    const parts = formatter.formatToParts(now)
    return parts.find((part) => part.type === 'timeZoneName')?.value
  } catch (err) {
    log.error('getDaylightSavingsAwareTz error: ', err)
    return null // timezone couldn't be determined
  }
}
