// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const tl = {
  conversationEndedThanks: 'Salamat sa pakikipag-chat sa amin!',
  conversationEndedMoreQuestions:
    'Kung mayroon ka pang tanong, huwag mag-atubiling makipag-ugnayan muli.',
  conversationEndedNewChat: 'Magsimula ng bagong chat',
  headerLiveChat: 'Live Chat',
  'language.en': 'Ingles',
  'language.ar': 'Arabic',
  'language.hy': 'Armenian',
  'language.zh': 'Chinese (Pinasimple)',
  'language.tl': 'Filipino/Tagalog',
  'language.hi': 'Hindi',
  'language.hmn': 'Hmong',
  'language.ja': 'Hapon',
  'language.km': 'Khmer',
  'language.ko': 'Koreano',
  'language.lo': 'Lao',
  'language.fa': 'Persian/Farsi',
  'language.pa': 'Punjabi',
  'language.ru': 'Ruso',
  'language.es': 'Espanyol',
  'language.th': 'Thai',
  'language.uk': 'Ukranian',
  'language.vi': 'Vietnamese',
  'language.other': 'Iba pa',
  loadingAuthorizing: 'Nagpapahintulot',
  messageBubbleYouSentAt: 'Nagpadala ka sa',
  messageBubbleSentAt: '{{name}} ipinadala sa',
  messageBubbleMediaMessagesNotSupported:
    'Hindi sinusuportahan ang mga mensahe sa media',
  messageBubbleRead: 'Basahin',
  messageInputInstructions: 'I-type ang iyong mensahe',
  messageInputSendMessage: 'Magpadala ng Mensahe',
  messageListAgent: 'Ahente',
  messageListChatStarted: 'Nagsimula ang chat',
  messageListIsTyping: '{{name}} ay nagta-type...',
  messageListLoading: 'Naglo-load',
  messageListNew: 'Bago',
  messageListToday: 'Ngayong araw',
  messageListYesterday: 'Kahapon',
  preEngagementHiThere: 'Kumusta!',
  preEngagementIntro:
    'Ito ang Soluna contact center at telecoaching web-chat. Magiging available kaming sagutin ang iyong mga tanong araw-araw',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro), sa pagitan ng 10am at 10pm {{timezoneAbbrev}}.',
  preEngagementName: 'Pangalan',
  preEngagementNameInstructions: 'Pakilagay ang iyong pangalan',
  preEngagementEmail: 'Email Address',
  preEngagementEmailInstructions: 'Pakilagay ang iyong email address',
  preEngagementLanguage: 'Wika',
  preEngagementHowCanWeHelp: 'Paano namin kayo matutulungan?',
  preEngagementInstructions: 'Magtanong',
  preEngagementStartChat: 'Simulan ang chat',
  preEngagementChatClosed: 'Isinara ang chat.',
  preEngagementChatClosedExplanation:
    'Sa kasamaang palad, kasalukuyang sarado ang contact center ng Soluna at telecoaching web-chat. Magiging available kaming sagutin ang iyong mga tanong araw-araw',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation), sa pagitan ng 10am at 10pm {{timezoneAbbrev}}.',
  transcriptDownload: 'I-download',
  transcriptGeneratingTranscript: 'Bumubuo ng transcript...',
  transcriptDownloading: 'Dina-download ang transcript...',
  transcriptSendToEmail: 'Ipadala sa aking email',
  transcriptSending: 'Nagpapadala ng transcript...',
  transcriptDoYouWantATranscript: 'Gusto mo ba ng transcript ng aming chat?',
}
