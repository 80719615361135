import { useEffect } from 'react'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { store } from './store/store'
import { WebchatWidget } from './components/WebchatWidget'
import { initConfig } from './store/actions/initActions'
import { ConfigState } from './store/definitions'
import { initLogger } from './logger'
import { SetupSentry } from './SetupSentry'

const config: ConfigState = {
  theme: {
    isLight: true,
    overrides: {
      zIndices: {
        // Boosting  "zIndex90" paste design token to 100, because the solunapp.com parent site's CSS explicitly sets
        // webchat components's zIndex to 100, and thus we need to boost the zIndex of the language selection drop-down
        // above its default of 90.
        // I'm changing the value of the "zIndex90" token in the theme because Twilio Paste
        // only allows you to specify zIndexes with one of their 10 predefined zIndex tokens, which are "zIndex0", "zIndex10",
        // "zIndex20", ... "zIndex90". There is no option to cleanly add new zIndex tokens or to use zIndex numeric values directly.
        // To change the value of zIndex tokens, you have to do it in the theme.
        zIndex90: 100,
      },
    },
  },
  transcript: {
    downloadEnabled: false,
    emailEnabled: false,
  },
}

export const App = () => {
  useEffect(() => {
    store.dispatch(initConfig(config))
    initLogger()
  }, [])

  return (
    <Provider store={store}>
      <SetupSentry />
      <Sentry.ErrorBoundary>
        <WebchatWidget />
      </Sentry.ErrorBoundary>
    </Provider>
  )
}
