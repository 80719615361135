// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const ru = {
  conversationEndedThanks: 'Спасибо, что общаетесь с нами!',
  conversationEndedMoreQuestions:
    'Если у вас остались вопросы, не стесняйтесь обращаться к нам снова.',
  conversationEndedNewChat: 'Начать новый чат',
  headerLiveChat: 'Чат',
  'language.en': 'Английский',
  'language.ar': 'Арабский',
  'language.hy': 'Армянский',
  'language.zh': 'Китайский (упрощенный)',
  'language.tl': 'Филиппинский/Тагальский',
  'language.hi': 'Хинди',
  'language.hmn': 'Хмонги',
  'language.ja': 'Японский',
  'language.km': 'Кхмерский',
  'language.ko': 'Корейский',
  'language.lo': 'Лаосский',
  'language.fa': 'Персидский/Фарси',
  'language.pa': 'Панджаби',
  'language.ru': 'Русский',
  'language.es': 'Испанский',
  'language.th': 'Тайский',
  'language.uk': 'Украинский',
  'language.vi': 'Вьетнамский',
  'language.other': 'Другой',
  loadingAuthorizing: 'Авторизации',
  messageBubbleYouSentAt: 'Вы отправили по адресу',
  messageBubbleSentAt: '{{name}} отправлено по адресу',
  messageBubbleMediaMessagesNotSupported: 'Медиа-сообщения не поддерживаются',
  messageBubbleRead: 'Читать',
  messageInputInstructions: 'Введите сообщение',
  messageInputSendMessage: 'Отправить сообщение',
  messageListAgent: 'Агент',
  messageListChatStarted: 'Чат запущен',
  messageListIsTyping: '{{name}} печатает...',
  messageListLoading: 'Погрузка',
  messageListNew: 'Новые функции',
  messageListToday: 'Сегодня',
  messageListYesterday: 'Вчера',
  preEngagementHiThere: 'Привет!',
  preEngagementIntro:
    'Это контакт-центр Soluna и веб-чат телекоучинга. Мы будем готовы ответить на ваши вопросы ежедневно',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro), с 10 утра до 10 вечера {{timezoneAbbrev}}.',
  preEngagementName: 'Имя',
  preEngagementNameInstructions: 'Пожалуйста, введите Ваше имя',
  preEngagementEmail: 'Адрес электронной почты',
  preEngagementEmailInstructions:
    'Пожалуйста, введите свой адрес электронной почты',
  preEngagementLanguage: 'Язык',
  preEngagementHowCanWeHelp: 'Чем мы можем вам помочь?',
  preEngagementInstructions: 'Задать вопрос',
  preEngagementStartChat: 'Начать чат',
  preEngagementChatClosed: 'Чат закрыт.',
  preEngagementChatClosedExplanation:
    'К сожалению, контакт-центр Soluna и веб-чат телекоучинга в настоящее время закрыты. Мы будем готовы ответить на ваши вопросы ежедневно',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation), с 10 утра до 10 вечера {{timezoneAbbrev}}.',
  transcriptDownload: 'Загружать',
  transcriptGeneratingTranscript: 'Генерация расшифровки...',
  transcriptDownloading: 'Скачивание стенограммы...',
  transcriptSendToEmail: 'Отправить на мою электронную почту',
  transcriptSending: 'Отправка стенограммы...',
  transcriptDoYouWantATranscript: 'Хотите расшифровку нашего чата?',
}
