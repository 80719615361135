// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const uk = {
  conversationEndedThanks: 'Дякуємо, що поспілкувалися з нами!',
  conversationEndedMoreQuestions:
    'Якщо у вас виникнуть додаткові запитання, не соромтеся звертатися до нас знову.',
  conversationEndedNewChat: 'Почніть новий чат',
  headerLiveChat: 'Живий чат',
  'language.en': 'Англійська мова',
  'language.ar': 'Арабська',
  'language.hy': 'Вірменська',
  'language.zh': 'Китайська (спрощена)',
  'language.tl': 'Філіппінська/тагальська',
  'language.hi': 'Хінді',
  'language.hmn': 'Хмонг',
  'language.ja': 'Японський',
  'language.km': 'Кхмер',
  'language.ko': 'Корейська',
  'language.lo': 'Лао.',
  'language.fa': 'Перська/фарсі',
  'language.pa': 'Пенджабі',
  'language.ru': 'Російська мова',
  'language.es': 'Іспанська',
  'language.th': 'Тайська',
  'language.uk': 'Українська мова',
  'language.vi': "В'єтнамці",
  'language.other': 'Інше',
  loadingAuthorizing: 'Авторизація',
  messageBubbleYouSentAt: 'Ви надіслали на адресу',
  messageBubbleSentAt: '{{name}} відправлено на',
  messageBubbleMediaMessagesNotSupported: 'Медіа-повідомлення не підтримуються',
  messageBubbleRead: 'Прочитайте',
  messageInputInstructions: 'Введіть своє повідомлення',
  messageInputSendMessage: 'Надіслати повідомлення',
  messageListAgent: 'Агенте.',
  messageListChatStarted: 'Розпочато чат',
  messageListIsTyping: '{{name}} друкує...',
  messageListLoading: 'Завантаження',
  messageListNew: 'Новий',
  messageListToday: 'Сьогодні',
  messageListYesterday: 'Вчора',
  preEngagementHiThere: 'Привіт!',
  preEngagementIntro:
    'Це контакт-центр Soluna та телекоучинговий веб-чат. Ми будемо готові відповісти на ваші запитання щодня',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro), між 10 ранку та 10 вечора {{timezoneAbbrev}}.',
  preEngagementName: "Ім'я",
  preEngagementNameInstructions: "Будь ласка, введіть своє ім'я",
  preEngagementEmail: 'Адреса електронної пошти',
  preEngagementEmailInstructions: 'Будь ласка, введіть свою електронну адресу',
  preEngagementLanguage: 'Мова',
  preEngagementHowCanWeHelp: 'Як ми можемо вам допомогти?',
  preEngagementInstructions: 'Поставити запитання',
  preEngagementStartChat: 'Почати чат',
  preEngagementChatClosed: 'Чат закрито.',
  preEngagementChatClosedExplanation:
    'На жаль, контакт-центр Soluna та веб-чат телекоучингу наразі закриті. Ми будемо готові відповісти на ваші запитання щодня',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation), між 10 ранку та 10 вечора {{timezoneAbbrev}}.',
  transcriptDownload: 'Завантажити',
  transcriptGeneratingTranscript: 'Генерування стенограми...',
  transcriptDownloading: 'Завантажити стенограму...',
  transcriptSendToEmail: 'Надішліть на мою електронну пошту',
  transcriptSending: 'Надсилаю стенограму...',
  transcriptDoYouWantATranscript: 'Хочете стенограму нашого чату?',
}
