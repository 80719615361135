// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const ar = {
  conversationEndedThanks: 'شكرا للدردشة معنا!',
  conversationEndedMoreQuestions:
    'إذا كان لديك أي أسئلة أخرى، فلا تتردد في التواصل معنا مرة أخرى.',
  conversationEndedNewChat: 'ابدأ محادثة جديدة',
  headerLiveChat: 'دردشة مباشرة',
  'language.en': 'إنجليزي',
  'language.ar': 'عربي',
  'language.hy': 'الأرمينية',
  'language.zh': 'الصينية المبسطة)',
  'language.tl': 'الفلبينية/التاغالوغية',
  'language.hi': 'الهندية',
  'language.hmn': 'همونغ',
  'language.ja': 'اليابانية',
  'language.km': 'الخمير',
  'language.ko': 'الكورية',
  'language.lo': 'لاو',
  'language.fa': 'الفارسية / الفارسية',
  'language.pa': 'البنجابية',
  'language.ru': 'الروسية',
  'language.es': 'الأسبانية',
  'language.th': 'التايلاندية',
  'language.uk': 'الأوكرانية',
  'language.vi': 'الفيتنامية',
  'language.other': 'آخر',
  loadingAuthorizing: 'التفويض',
  messageBubbleYouSentAt: 'لقد أرسلت في',
  messageBubbleSentAt: 'تم إرسال {{name}} على',
  messageBubbleMediaMessagesNotSupported: 'رسائل الوسائط غير مدعومة',
  messageBubbleRead: 'يقرأ',
  messageInputInstructions: 'اكتب رسالتك',
  messageInputSendMessage: 'أرسل رسالة',
  messageListAgent: 'عامل',
  messageListChatStarted: 'بدأت الدردشة',
  messageListIsTyping: '{{name}} يكتب...',
  messageListLoading: 'تحميل',
  messageListNew: 'جديد',
  messageListToday: 'اليوم',
  messageListYesterday: 'أمس',
  preEngagementHiThere: 'أهلاً!',
  preEngagementIntro:
    'هذا هو مركز اتصال Soluna والدردشة عبر الإنترنت للتدريب عن بعد. سنكون متاحين للإجابة على أسئلتك يوميا',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro)، بين الساعة 10 صباحًا و10 مساءً {{timezoneAbbrev}}.',
  preEngagementName: 'اسم',
  preEngagementNameInstructions: 'من فضلك أدخل إسمك',
  preEngagementEmail: 'عنوان البريد الإلكتروني',
  preEngagementEmailInstructions:
    'الرجاء إدخال عنوان البريد الإلكتروني الخاص بك',
  preEngagementLanguage: 'لغة',
  preEngagementHowCanWeHelp: 'كيف يمكننا مساعدتك؟',
  preEngagementInstructions: 'طرح سؤال',
  preEngagementStartChat: 'ابدأ الدردشة',
  preEngagementChatClosed: 'الدردشة مغلقة.',
  preEngagementChatClosedExplanation:
    'لسوء الحظ، مركز اتصال Soluna والدردشة عبر الإنترنت للتدريب عن بعد مغلقان حاليًا. سنكون متاحين للإجابة على أسئلتك يوميا',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation)، بين الساعة 10 صباحًا و10 مساءً {{timezoneAbbrev}}.',
  transcriptDownload: 'تحميل',
  transcriptGeneratingTranscript: 'جارٍ إنشاء النص...',
  transcriptDownloading: 'جارٍ تنزيل النص...',
  transcriptSendToEmail: 'أرسل إلى بريدي الإلكتروني',
  transcriptSending: 'جارٍ إرسال النص...',
  transcriptDoYouWantATranscript: 'هل تريد نسخة من محادثتنا؟',
}
