import { AnyAction, Reducer } from 'redux'

import { ConfigState } from './definitions'
import { ACTION_LOAD_CONFIG } from './actions/actionTypes'

const initialState: ConfigState = {}

export const ConfigReducer: Reducer = (
  state: ConfigState = initialState,
  action: AnyAction
): ConfigState => {
  if (action.type === ACTION_LOAD_CONFIG) {
    return {
      ...state,
      ...action.payload,
    }
  }

  return state
}
