import i18n, { Resource } from 'i18next'
import { initReactI18next, I18nextProvider } from 'react-i18next'
import languageDetector from 'i18next-browser-languagedetector'
import { PropsWithChildren, useEffect } from 'react'
import { translations } from './translations'

// Note: this makes the languageDetector check your navigator first,
// which is useful if you need to test different locales via the
// Sensor's Location Override in Devtools. Without this, the
// detector checks other things first, which are not affected by
// Sensors.

const DETECTION_OPTIONS = {
  detection: {
    order: [
      'querystring',
      'subdomain',
      'htmlTag',
      'cookie',
      'localStorage',
      'sessionStorage',
      'navigator',
    ],
  },
}

export const I18nWrapper = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    const resources = Object.entries(translations).reduce<Resource>(
      (acc, [language, mapping]) => ({
        ...acc,
        [language]: { translation: mapping },
      }),
      {}
    )
    i18n
      .use(initReactI18next)
      .use(languageDetector)
      .init({
        ...DETECTION_OPTIONS,
        resources,
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false,
        },
      })
  }, [])

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

export const withTranslations = (Component: React.ReactNode) => (
  <I18nWrapper>{Component}</I18nWrapper>
)
