import log from 'loglevel'
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import throttle from 'lodash.throttle'
import { useSelector } from 'react-redux'
import { Box } from '@twilio-paste/core/box'
import { InputBox } from '@twilio-paste/core/input-box'
import { TextArea } from '@twilio-paste/core/textarea'
import { Button } from '@twilio-paste/core/button'
import { SendIcon } from '@twilio-paste/icons/esm/SendIcon'

import { AppState } from '../store/definitions'
import { CHAR_LIMIT } from '../constants'
import {
  formStyles,
  innerInputStyles,
  messageOptionContainerStyles,
  textAreaContainerStyles,
} from './styles/MessageInput.styles'
import { useSafeTranslation } from '../i18n/useSafeTranslation'

export const MessageInput = () => {
  const t = useSafeTranslation()
  const [text, setText] = useState('')
  const [isSending, setIsSending] = useState(false)
  const { conversation } = useSelector((state: AppState) => ({
    conversation: state.chat.conversation,
  }))
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const throttleChange = useMemo(
    () =>
      throttle(() => {
        conversation?.typing()

        // in case the input was already focused, let's make sure to send the `read` status if the customer is typing
        if (
          conversation?.lastReadMessageIndex !==
          conversation?.lastMessage?.index
        ) {
          conversation?.setAllMessagesRead()
        }
      }, 500),
    [conversation]
  )

  const isSubmitDisabled = !text.trim() || isSending

  const send = async () => {
    if (isSubmitDisabled) {
      return
    }
    if (!conversation) {
      log.error('Failed sending message: no conversation found')
      return
    }
    setIsSending(true)

    await conversation.prepareMessage().setBody(text).build().send()

    setText('')
    setIsSending(false)
    textAreaRef.current?.focus()
  }

  const onKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      ;(e.target as HTMLInputElement).form?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      )
    }
  }

  const onChange = (val: ChangeEvent<HTMLTextAreaElement>) => {
    setText(val.target.value)

    throttleChange()
  }

  const onFocus = () => {
    conversation?.setAllMessagesRead()
  }

  useEffect(() => {
    textAreaRef.current?.setAttribute('rows', '1')
    textAreaRef.current?.focus()
  }, [textAreaRef])

  return (
    <Box
      as="form"
      {...formStyles}
      onSubmit={(e) => {
        e.preventDefault()
        send()
      }}
    >
      <InputBox element="MESSAGE_INPUT_BOX" disabled={isSending}>
        <Box as="div" {...innerInputStyles}>
          <Box {...textAreaContainerStyles}>
            <TextArea
              ref={textAreaRef}
              data-test="message-input-textarea"
              placeholder={t('messageInputInstructions')}
              value={text}
              element="MESSAGE_INPUT"
              onChange={onChange}
              onFocus={onFocus}
              readOnly={isSending}
              onKeyPress={onKeyPress}
              maxLength={CHAR_LIMIT}
            />
          </Box>
          <Box {...messageOptionContainerStyles}>
            <Button
              data-test="message-send-button"
              variant="primary_icon"
              size="icon_small"
              type="submit"
              aria-disabled={isSubmitDisabled}
            >
              <SendIcon
                decorative={false}
                title={t('messageInputSendMessage')}
                size="sizeIcon30"
              />
            </Button>
          </Box>
        </Box>
      </InputBox>
    </Box>
  )
}
