import { Flex } from '@twilio-paste/core/flex'
import { Spinner } from '@twilio-paste/core/spinner'
import { useSafeTranslation } from '../i18n/useSafeTranslation'

export const LoadingPhase = () => {
  const t = useSafeTranslation()
  return (
    <Flex hAlignContent="center" vAlignContent="center" height="100%">
      <Spinner
        title={t('loadingAuthorizing')}
        decorative={false}
        size="sizeIcon100"
        color="colorTextLink"
      />
    </Flex>
  )
}
