// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const ko = {
  conversationEndedThanks: '채팅해 주셔서 감사합니다!',
  conversationEndedMoreQuestions:
    '더 궁금한 점이 있으시면 언제든지 다시 문의해 주세요.',
  conversationEndedNewChat: '새 채팅 시작하기',
  headerLiveChat: '실시간 채팅',
  'language.en': '영어',
  'language.ar': '아랍어',
  'language.hy': '아르메니아어',
  'language.zh': '중국어(간체)',
  'language.tl': '필리핀어/타갈로그어',
  'language.hi': '힌디어',
  'language.hmn': '몽족',
  'language.ja': '일본어',
  'language.km': '크메르어',
  'language.ko': '한국어',
  'language.lo': 'Lao',
  'language.fa': '페르시아어/파르시아어',
  'language.pa': '펀자브어',
  'language.ru': '러시아어',
  'language.es': '스페인어',
  'language.th': 'Thai',
  'language.uk': '우크라이나어',
  'language.vi': '베트남어',
  'language.other': '기타',
  loadingAuthorizing: '권한 부여',
  messageBubbleYouSentAt: '다음 주소로 보냈습니다.',
  messageBubbleSentAt: '{{name}} 에서 보낸',
  messageBubbleMediaMessagesNotSupported: '미디어 메시지는 지원되지 않습니다.',
  messageBubbleRead: '읽기',
  messageInputInstructions: '메시지 입력',
  messageInputSendMessage: '메시지 보내기',
  messageListAgent: '에이전트',
  messageListChatStarted: '채팅 시작',
  messageListIsTyping: '{{name}} 입력 중 ...',
  messageListLoading: '로드 중',
  messageListNew: '신규',
  messageListToday: '오늘',
  messageListYesterday: '어제',
  preEngagementHiThere: '안녕하세요!',
  preEngagementIntro:
    'Soluna 컨택 센터 및 텔레코칭 웹 채팅입니다. 매일 질문에 답변해 드립니다.',
  preEngagementIntroWithHours:
    '오전 10시에서 오후 10시 {{timezoneAbbrev}} 사이에 $t(preEngagementIntro)를 입력합니다.',
  preEngagementName: '이름',
  preEngagementNameInstructions: '이름을 입력하세요.',
  preEngagementEmail: '이메일 주소',
  preEngagementEmailInstructions: '이메일 주소를 입력하세요.',
  preEngagementLanguage: '언어',
  preEngagementHowCanWeHelp: '무엇을 도와드릴까요?',
  preEngagementInstructions: '질문하기',
  preEngagementStartChat: '채팅 시작',
  preEngagementChatClosed: '채팅이 종료되었습니다.',
  preEngagementChatClosedExplanation:
    '안타깝게도 현재 Soluna 문의 센터 및 텔레코칭 웹 채팅은 운영이 중단되었습니다. 매일 질문에 답변해 드립니다.',
  preEngagementChatClosedExplanationWithHours:
    '오전 10시에서 오후 10시 {{timezoneAbbrev}} 사이, $t(preEngagementChatClosedExplanation).',
  transcriptDownload: '다운로드',
  transcriptGeneratingTranscript: '성적 증명서 생성...',
  transcriptDownloading: '성적 증명서 다운로드...',
  transcriptSendToEmail: '내 이메일로 보내기',
  transcriptSending: '성적 증명서 보내기...',
  transcriptDoYouWantATranscript: '채팅 기록을 원하시나요?',
}
