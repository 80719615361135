import { useTranslation } from 'react-i18next'
import { I18nKeyExtended, ParamsForKey, ParamsTypeForKey } from './types'

type SafeFunction = <K extends I18nKeyExtended>(
  key: K,
  ...args: ParamsForKey<K> extends never ? [] : [obj: ParamsTypeForKey<K>]
) => string

export type SafeTranslation = SafeFunction & {
  unsafe: (key: string, opts?: Record<string, any>) => string
}

export const useSafeTranslation = (): SafeTranslation => {
  const { t } = useTranslation()

  const safe = <K extends I18nKeyExtended>(
    key: K,
    ...args: ParamsForKey<K> extends never ? [] : [obj: ParamsTypeForKey<K>]
  ): string => {
    const value = t(key, args[0] || (undefined as any)) as any as string
    return value || key
  }

  safe.unsafe = t as any
  return safe
}
