/*
 * Gets the number of days between the given date and the current date.
 * e.g. if today is January 10th 2022 then January 8th 2022 returns 2
 */
export const getDaysOld = (date: Date, now = new Date()): number => {
  const messageDate = new Date(date.getTime())
  messageDate.setHours(0)
  messageDate.setMinutes(0)
  messageDate.setSeconds(0, 0)

  const currentDate = new Date(now.valueOf())
  currentDate.setHours(0)
  currentDate.setMinutes(0)
  currentDate.setSeconds(0, 0)

  const timeDiff = currentDate.getTime() - messageDate.getTime()

  return Math.round(timeDiff / (1000 * 3600 * 24))
}
