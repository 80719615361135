import { Message } from '@twilio/conversations'
import { Box } from '@twilio-paste/core/box'
import { ScreenReaderOnly } from '@twilio-paste/core/screen-reader-only'
import { useSelector } from 'react-redux'
import { Text } from '@twilio-paste/core/text'
import { Flex } from '@twilio-paste/core/flex'
import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { SuccessIcon } from '@twilio-paste/icons/esm/SuccessIcon'

import { AppState } from '../store/definitions'
import { parseMessageBody } from '../utils/parseMessageBody'
import {
  getAvatarContainerStyles,
  getInnerContainerStyles,
  authorStyles,
  timeStampStyles,
  bodyStyles,
  outerContainerStyles,
  readStatusStyles,
  bubbleAndAvatarContainerStyles,
} from './styles/MessageBubble.styles'

import solunaBotIcon from './images/soluna-bot-icon.png'
import { useSafeTranslation } from '../i18n/useSafeTranslation'

const doubleDigit = (number: number) => `${number < 10 ? 0 : ''}${number}`

export const MessageBubble = ({
  message,
  isLast,
  isLastOfUserGroup,
  focusable,
  updateFocus,
}: {
  message: Message
  isLast: boolean
  isLastOfUserGroup: boolean
  focusable: boolean
  updateFocus: (newFocus: number) => void
}) => {
  const t = useSafeTranslation()
  const [read, setRead] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const { conversationsClient, participants, users } = useSelector(
    (state: AppState) => ({
      conversationsClient: state.chat.conversationsClient,
      participants: state.chat.participants,
      users: state.chat.users,
    })
  )
  const messageRef = useRef<HTMLDivElement>(null)

  const belongsToCurrentUser =
    message.author === conversationsClient?.user.identity

  useEffect(() => {
    if (isLast && participants && belongsToCurrentUser) {
      const getOtherParticipants = participants.filter(
        (p) => p.identity !== conversationsClient?.user.identity
      )
      setRead(
        Boolean(getOtherParticipants.length) &&
          getOtherParticipants.every(
            (p) => p.lastReadMessageIndex === message.index
          )
      )
    } else {
      setRead(false)
    }
  }, [participants, isLast, belongsToCurrentUser, conversationsClient, message])

  useEffect(() => {
    if (focusable) {
      messageRef.current?.focus()
    }
  }, [focusable])

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      const newFocusValue = message.index + (e.key === 'ArrowUp' ? -1 : 1)
      updateFocus(newFocusValue)
    }
  }

  const handleMouseDown = () => {
    setIsMouseDown(true)
  }

  const handleMouseUp = () => {
    setIsMouseDown(false)
  }

  const handleFocus = () => {
    // Ignore focus from clicks
    if (!isMouseDown) {
      // Necessary since screen readers can set the focus to any focusable element
      updateFocus(message.index)
    }
  }

  const user = users?.find((u) => u.identity === message.author)
  const authorName = user?.friendlyName || message.author

  return (
    <Box
      {...outerContainerStyles}
      tabIndex={focusable ? 0 : -1}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      ref={messageRef}
      data-message-bubble
      data-testid="message-bubble"
    >
      <Box {...bubbleAndAvatarContainerStyles}>
        {!belongsToCurrentUser && (
          <Box
            {...getAvatarContainerStyles(!isLastOfUserGroup)}
            data-testid="avatar-container"
          >
            {isLastOfUserGroup && (
              <Box
                as="img"
                src={solunaBotIcon}
                alt=""
                maxHeight="100%"
                maxWidth="100%"
              />
            )}
          </Box>
        )}
        <Box {...getInnerContainerStyles(belongsToCurrentUser)}>
          <Flex
            hAlignContent="between"
            width="100%"
            vAlignContent="center"
            marginBottom="space20"
          >
            <Text
              {...authorStyles}
              as="p"
              aria-hidden
              style={{ textOverflow: 'ellipsis' }}
              title={authorName}
            >
              {authorName}
            </Text>
            <ScreenReaderOnly as="p">
              {belongsToCurrentUser
                ? t('messageBubbleYouSentAt')
                : t('messageBubbleSentAt', { name: authorName })}
            </ScreenReaderOnly>
            <Text {...timeStampStyles} as="p">
              {`${doubleDigit(message.dateCreated.getHours())}:${doubleDigit(
                message.dateCreated.getMinutes()
              )}`}
            </Text>
          </Flex>
          <Text as="p" {...bodyStyles}>
            {message.body
              ? parseMessageBody(message.body, belongsToCurrentUser)
              : null}
          </Text>
          {message.type === 'media' ? (
            <i>{t('messageBubbleMediaMessagesNotSupported')}</i>
          ) : null}
        </Box>
      </Box>
      {read && (
        <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
          <Text as="p" {...readStatusStyles}>
            {t('messageBubbleRead')}
          </Text>
          <SuccessIcon
            decorative={true}
            size="sizeIcon10"
            color="colorTextWeak"
          />
        </Flex>
      )}
    </Box>
  )
}
