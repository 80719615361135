// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const pa = {
  conversationEndedThanks: 'ਸਾਡੇ ਨਾਲ ਗੱਲਬਾਤ ਕਰਨ ਲਈ ਧੰਨਵਾਦ!',
  conversationEndedMoreQuestions:
    'ਜੇਕਰ ਤੁਹਾਡੇ ਕੋਈ ਹੋਰ ਸਵਾਲ ਹਨ, ਤਾਂ ਬੇਝਿਜਕ ਦੁਬਾਰਾ ਸੰਪਰਕ ਕਰੋ।',
  conversationEndedNewChat: 'ਨਵੀਂ ਗੱਲਬਾਤ ਸ਼ੁਰੂ ਕਰੋ',
  headerLiveChat: 'ਲਾਈਵ ਚੈਟ',
  'language.en': 'ਅੰਗਰੇਜ਼ੀ',
  'language.ar': 'ਅਰਬੀ',
  'language.hy': 'ਅਰਮੀਨੀਆਈ',
  'language.zh': 'ਚੀਨੀ (ਸਰਲੀਕ੍ਰਿਤ)',
  'language.tl': 'ਫਿਲੀਪੀਨੋ/ਤਾਗਾਲੋਗ',
  'language.hi': 'ਹਿੰਦੀ',
  'language.hmn': 'ਹਮੋਂਗ',
  'language.ja': 'ਜਾਪਾਨੀ',
  'language.km': 'ਖਮੇਰ',
  'language.ko': 'ਕੋਰੀਅਨ',
  'language.lo': 'ਲਾਓ',
  'language.fa': 'ਫ਼ਾਰਸੀ/ਫ਼ਾਰਸੀ',
  'language.pa': 'ਪੰਜਾਬੀ',
  'language.ru': 'ਰੂਸੀ',
  'language.es': 'ਸਪੇਨੀ',
  'language.th': 'ਥਾਈ',
  'language.uk': 'ਯੂਕਰੇਨੀਅਨ',
  'language.vi': 'ਵੀਅਤਨਾਮੀ',
  'language.other': 'ਹੋਰ',
  loadingAuthorizing: 'ਅਧਿਕਾਰਤ ਕਰ ਰਿਹਾ ਹੈ',
  messageBubbleYouSentAt: "ਤੁਸੀਂ 'ਤੇ ਭੇਜਿਆ ਹੈ",
  messageBubbleSentAt: "{{name}} 'ਤੇ ਭੇਜਿਆ ਗਿਆ",
  messageBubbleMediaMessagesNotSupported: 'ਮੀਡੀਆ ਸੁਨੇਹੇ ਸਮਰਥਿਤ ਨਹੀਂ ਹਨ',
  messageBubbleRead: 'ਪੜ੍ਹੋ',
  messageInputInstructions: 'ਆਪਣਾ ਸੁਨੇਹਾ ਟਾਈਪ ਕਰੋ',
  messageInputSendMessage: 'ਸੁਨੇਹਾ ਭੇਜੋ',
  messageListAgent: 'ਏਜੰਟ',
  messageListChatStarted: 'ਗੱਲਬਾਤ ਸ਼ੁਰੂ ਹੋ ਗਈ',
  messageListIsTyping: '{{name}} ਟਾਈਪ ਕਰ ਰਿਹਾ ਹੈ...',
  messageListLoading: 'ਲੋਡ ਹੋ ਰਿਹਾ ਹੈ',
  messageListNew: 'ਨਵਾਂ',
  messageListToday: 'ਅੱਜ',
  messageListYesterday: 'ਕੱਲ੍ਹ',
  preEngagementHiThere: 'ਸਤ ਸ੍ਰੀ ਅਕਾਲ!',
  preEngagementIntro:
    'ਇਹ Soluna ਸੰਪਰਕ ਕੇਂਦਰ ਅਤੇ ਟੈਲੀਕੋਚਿੰਗ ਵੈੱਬ-ਚੈਟ ਹੈ। ਅਸੀਂ ਰੋਜ਼ਾਨਾ ਤੁਹਾਡੇ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦੇਣ ਲਈ ਉਪਲਬਧ ਹੋਵਾਂਗੇ',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro), ਸਵੇਰੇ 10am ਅਤੇ 10pm ਦੇ ਵਿਚਕਾਰ {{timezoneAbbrev}}.',
  preEngagementName: 'ਨਾਮ',
  preEngagementNameInstructions: 'ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਨਾਮ ਦਰਜ ਕਰੋ',
  preEngagementEmail: 'ਈਮੇਲ ਪਤਾ',
  preEngagementEmailInstructions: 'ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਈਮੇਲ ਪਤਾ ਦਾਖਲ ਕਰੋ',
  preEngagementLanguage: 'ਭਾਸ਼ਾ',
  preEngagementHowCanWeHelp: 'ਅਸੀਂ ਤੁਹਾਡੀ ਮਦਦ ਕਿਵੇਂ ਕਰ ਸਕਦੇ ਹਾਂ?',
  preEngagementInstructions: 'ਸਵਾਲ ਕਰੋ',
  preEngagementStartChat: 'ਚੈਟ ਸ਼ੁਰੂ ਕਰੋ',
  preEngagementChatClosed: 'ਚੈਟ ਬੰਦ।',
  preEngagementChatClosedExplanation:
    'ਬਦਕਿਸਮਤੀ ਨਾਲ Soluna ਸੰਪਰਕ ਕੇਂਦਰ ਅਤੇ ਟੈਲੀਕੋਚਿੰਗ ਵੈੱਬ-ਚੈਟ ਵਰਤਮਾਨ ਵਿੱਚ ਬੰਦ ਹੈ। ਅਸੀਂ ਰੋਜ਼ਾਨਾ ਤੁਹਾਡੇ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬ ਦੇਣ ਲਈ ਉਪਲਬਧ ਹੋਵਾਂਗੇ',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation), ਸਵੇਰੇ 10am ਅਤੇ 10pm {{timezoneAbbrev}} ਵਿਚਕਾਰ।',
  transcriptDownload: 'ਡਾਊਨਲੋਡ ਕਰੋ',
  transcriptGeneratingTranscript: 'ਪ੍ਰਤੀਲਿਪੀ ਤਿਆਰ ਕੀਤੀ ਜਾ ਰਹੀ ਹੈ...',
  transcriptDownloading: 'ਪ੍ਰਤੀਲਿਪੀ ਡਾਊਨਲੋਡ ਕੀਤੀ ਜਾ ਰਹੀ ਹੈ...',
  transcriptSendToEmail: "ਮੇਰੀ ਈਮੇਲ 'ਤੇ ਭੇਜੋ",
  transcriptSending: 'ਪ੍ਰਤੀਲਿਪੀ ਭੇਜੀ ਜਾ ਰਹੀ ਹੈ...',
  transcriptDoYouWantATranscript: 'ਕੀ ਤੁਸੀਂ ਸਾਡੀ ਚੈਟ ਦੀ ਪ੍ਰਤੀਲਿਪੀ ਚਾਹੁੰਦੇ ਹੋ?',
}
