export const en = {
  conversationEndedThanks: 'Thanks for chatting with us!',
  conversationEndedMoreQuestions:
    'If you have any more questions, feel free to reach out again.',
  conversationEndedNewChat: 'Start new chat',
  headerLiveChat: 'Live Chat',
  'language.en': 'English',
  'language.ar': 'Arabic',
  'language.hy': 'Armenian',
  'language.zh': 'Chinese (Simplified)',
  'language.tl': 'Filipino/Tagalog',
  'language.hi': 'Hindi',
  'language.hmn': 'Hmong',
  'language.ja': 'Japanese',
  'language.km': 'Khmer',
  'language.ko': 'Korean',
  'language.lo': 'Lao',
  'language.fa': 'Persian/Farsi',
  'language.pa': 'Punjabi',
  'language.ru': 'Russian',
  'language.es': 'Spanish',
  'language.th': 'Thai',
  'language.uk': 'Ukranian',
  'language.vi': 'Vietnamese',
  'language.other': 'Other',
  loadingAuthorizing: 'Authorizing',
  messageBubbleYouSentAt: 'You sent at',
  messageBubbleSentAt: '{{name}} sent at',
  messageBubbleMediaMessagesNotSupported: 'Media messages are not supported',
  messageBubbleRead: 'Read',
  messageInputInstructions: 'Type your message',
  messageInputSendMessage: 'Send message',
  messageListAgent: 'Agent',
  messageListChatStarted: 'Chat started',
  messageListIsTyping: '{{name}} is typing...',
  messageListLoading: 'Loading',
  messageListNew: 'New',
  messageListToday: 'Today',
  messageListYesterday: 'Yesterday',
  preEngagementHiThere: 'Hi There!',
  preEngagementIntro:
    "This is the Soluna contact center and telecoaching web-chat. We'll be available to answer your questions daily",
  preEngagementIntroWithHours:
    '$t(preEngagementIntro), between 10am and 10pm {{timezoneAbbrev}}.',
  preEngagementName: 'Name',
  preEngagementNameInstructions: 'Please enter your name',
  preEngagementEmail: 'Email address',
  preEngagementEmailInstructions: 'Please enter your email address',
  preEngagementLanguage: 'Preferred chat language',
  preEngagementHowCanWeHelp: 'How can we help you?',
  preEngagementInstructions: 'Ask a question',
  preEngagementStartChat: 'Start chat',
  preEngagementChatClosed: 'Chat closed.',
  preEngagementChatClosedExplanation:
    "Unfortunately the Soluna contact center and telecoaching web-chat is currently closed. We'll be available to answer your questions daily",
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation), between 10am and 10pm {{timezoneAbbrev}}.',
  transcriptDownload: 'Download',
  transcriptGeneratingTranscript: 'Generating transcript...',
  transcriptDownloading: 'Downloading transcript...',
  transcriptSendToEmail: 'Send to my email',
  transcriptSending: 'Sending transcript...',
  transcriptDoYouWantATranscript: 'Do you want a transcript of our chat?',
} as const
