// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const fa = {
  conversationEndedThanks: 'از اینکه با ما گپ زدید متشکریم!',
  conversationEndedMoreQuestions:
    'اگر سوال دیگری دارید، می توانید دوباره تماس بگیرید.',
  conversationEndedNewChat: 'شروع چت جدید',
  headerLiveChat: 'چت زنده',
  'language.en': 'انگلیسی',
  'language.ar': 'عربی',
  'language.hy': 'ارمنی',
  'language.zh': 'زبان چینی ساده شده)',
  'language.tl': 'فیلیپینی/تاگالوگ',
  'language.hi': 'هندی',
  'language.hmn': 'همونگ',
  'language.ja': 'ژاپنی',
  'language.km': 'خمر',
  'language.ko': 'کره ای',
  'language.lo': 'لائوس',
  'language.fa': 'فارسی/فارسی',
  'language.pa': 'پنجابی',
  'language.ru': 'روسی',
  'language.es': 'اسپانیایی',
  'language.th': 'تایلندی',
  'language.uk': 'اوکراینی',
  'language.vi': 'ویتنامی',
  'language.other': 'دیگر',
  loadingAuthorizing: 'مجاز کردن',
  messageBubbleYouSentAt: 'شما ارسال کردید در',
  messageBubbleSentAt: '{{name}} ارسال شد در',
  messageBubbleMediaMessagesNotSupported: 'پیام های رسانه ای پشتیبانی نمی شوند',
  messageBubbleRead: 'خواندن',
  messageInputInstructions: 'پیام خود را تایپ کنید',
  messageInputSendMessage: 'پیام فرستادن',
  messageListAgent: 'عامل',
  messageListChatStarted: 'چت شروع شد',
  messageListIsTyping: '{{name}} در حال تایپ است...',
  messageListLoading: 'بارگذاری',
  messageListNew: 'جدید',
  messageListToday: 'امروز',
  messageListYesterday: 'دیروز',
  preEngagementHiThere: 'سلام!',
  preEngagementIntro:
    'این مرکز تماس Soluna و گفتگوی اینترنتی از راه دور است. ما روزانه آماده پاسخگویی به سوالات شما خواهیم بود',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro)، بین ساعت 10 صبح تا 10 شب {{timezoneAbbrev}}.',
  preEngagementName: 'نام',
  preEngagementNameInstructions: 'لطفا نام خود را وارد کنید',
  preEngagementEmail: 'آدرس ایمیل',
  preEngagementEmailInstructions: 'لطفا آدرس ایمیل خود را وارد کنید',
  preEngagementLanguage: 'زبان',
  preEngagementHowCanWeHelp: 'چگونه ما میتوانیم به شما کمک کنیم؟',
  preEngagementInstructions: 'یه سوال بپرس',
  preEngagementStartChat: 'چت را شروع کنید',
  preEngagementChatClosed: 'چت بسته شد',
  preEngagementChatClosedExplanation:
    'متأسفانه مرکز تماس Soluna و گفتگوی اینترنتی از راه دور در حال حاضر بسته است. ما روزانه آماده پاسخگویی به سوالات شما خواهیم بود',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation)، بین ساعت 10 صبح تا 10 شب {{timezoneAbbrev}}.',
  transcriptDownload: 'دانلود',
  transcriptGeneratingTranscript: 'در حال تولید رونوشت...',
  transcriptDownloading: 'در حال بارگیری رونوشت...',
  transcriptSendToEmail: 'به ایمیلم بفرست',
  transcriptSending: 'ارسال رونوشت...',
  transcriptDoYouWantATranscript: 'آیا متنی از چت ما می خواهید؟',
}
