// Config reducer actions
export const ACTION_LOAD_CONFIG = 'ACTION_LOAD_CONFIG'

// Chat reducer actions
export const ACTION_CHANGE_EXPANDED_STATUS = 'ACTION_CHANGE_EXPANDED_STATUS'
export const ACTION_CHANGE_ENGAGEMENT_PHASE = 'ACTION_CHANGE_ENGAGEMENT_PHASE'
export const ACTION_START_SESSION = 'ACTION_START_SESSION'
export const ACTION_UPDATE_PARTICIPANT = 'ACTION_UPDATE_PARTICIPANT'
export const ACTION_ADD_PARTICIPANT = 'ACTION_ADD_PARTICIPANT'
export const ACTION_REMOVE_PARTICIPANT = 'ACTION_REMOVE_PARTICIPANT'
export const ACTION_UPDATE_MESSAGE = 'ACTION_UPDATE_MESSAGE'
export const ACTION_ADD_MESSAGE = 'ACTION_ADD_MESSAGE'
export const ACTION_REMOVE_MESSAGE = 'ACTION_REMOVE_MESSAGE'
export const ACTION_ADD_MULTIPLE_MESSAGES = 'ACTION_ADD_MULTIPLE_MESSAGES'
export const ACTION_UPDATE_CONVERSATION_STATE =
  'ACTION_UPDATE_CONVERSATION_STATE'
export const ACTION_UPDATE_SESSION_DATA = 'ACTION_UPDATE_SESSION_DATA'
export const ACTION_UPDATE_PRE_ENGAGEMENT_DATA =
  'ACTION_UPDATE_PRE_ENGAGEMENT_DATA'

// Notification reducer actions
export const ACTION_ADD_NOTIFICATION = 'ACTION_ADD_NOTIFICATION'
export const ACTION_REMOVE_NOTIFICATION = 'ACTION_REMOVE_NOTIFICATION'
