import { AnyAction, Reducer } from 'redux'
import { Message, Participant } from '@twilio/conversations'

import { ChatState } from './definitions'
import {
  ACTION_ADD_MESSAGE,
  ACTION_ADD_MULTIPLE_MESSAGES,
  ACTION_ADD_PARTICIPANT,
  ACTION_REMOVE_MESSAGE,
  ACTION_REMOVE_PARTICIPANT,
  ACTION_START_SESSION,
  ACTION_UPDATE_CONVERSATION_STATE,
  ACTION_UPDATE_MESSAGE,
  ACTION_UPDATE_PARTICIPANT,
} from './actions/actionTypes'

const initialState: ChatState = {}

export const ChatReducer: Reducer = (
  state: ChatState = initialState,
  action: AnyAction
): ChatState => {
  switch (action.type) {
    case ACTION_START_SESSION: {
      return {
        ...state,
        conversationsClient: action.payload.conversationsClient,
        conversation: action.payload.conversation,
        conversationState: action.payload.conversationState,
        users: action.payload.users,
        participants: action.payload.participants,
        messages: action.payload.messages,
      }
    }
    case ACTION_ADD_MULTIPLE_MESSAGES: {
      const messages = action.payload.messages as Message[]
      return {
        ...state,
        messages: messages.concat(state.messages ?? []),
      }
    }
    case ACTION_ADD_MESSAGE: {
      return {
        ...state,
        messages: (state.messages ?? []).concat(action.payload.message),
      }
    }
    case ACTION_REMOVE_MESSAGE: {
      return {
        ...state,
        messages: state.messages?.filter(
          (m) => m.sid !== action.payload.message.sid
        ),
      }
    }
    case ACTION_UPDATE_MESSAGE: {
      const message = action.payload.message as Message
      return {
        ...state,
        messages: state.messages?.map((m) =>
          m.sid === message.sid ? message : m
        ),
      }
    }
    case ACTION_ADD_PARTICIPANT: {
      return {
        ...state,
        participants: (state.participants ?? []).concat(
          action.payload.participant
        ),
        users: (state.users ?? []).concat(action.payload.user),
      }
    }
    case ACTION_REMOVE_PARTICIPANT: {
      return {
        ...state,
        participants: state.participants?.filter(
          (p) => p.sid !== action.payload.participant.sid
        ),
        users: state.users?.filter(
          (u) => u.identity !== action.payload.participant.identity
        ),
      }
    }
    case ACTION_UPDATE_PARTICIPANT: {
      const participant = action.payload.participant as Participant
      return {
        ...state,
        participants: state.participants?.map((p) =>
          p.sid === participant.sid ? participant : p
        ),
      }
    }
    case ACTION_UPDATE_CONVERSATION_STATE: {
      return {
        ...state,
        conversationState: action.payload.conversationState,
      }
    }

    default:
      return state
  }
}
