// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const vi = {
  conversationEndedThanks: 'Cảm ơn bạn đã trò chuyện với chúng tôi!',
  conversationEndedMoreQuestions:
    'Nếu bạn có thêm bất kỳ câu hỏi nào, vui lòng liên hệ lại.',
  conversationEndedNewChat: 'Bắt đầu cuộc trò chuyện mới',
  headerLiveChat: 'Trò chuyện trực tiếp',
  'language.en': 'Tiếng Anh',
  'language.ar': 'tiếng Ả Rập',
  'language.hy': 'tiếng Armenia',
  'language.zh': 'Tiếng Trung (Giản thể)',
  'language.tl': 'Tiếng Philippin/Tagalog',
  'language.hi': 'Tiếng Hindi',
  'language.hmn': 'người Mông',
  'language.ja': 'tiếng Nhật',
  'language.km': 'tiếng Khmer',
  'language.ko': 'Hàn Quốc',
  'language.lo': 'tiếng Lào',
  'language.fa': 'Tiếng Ba Tư/tiếng Farsi',
  'language.pa': 'Tiếng Punjab',
  'language.ru': 'tiếng Nga',
  'language.es': 'người Tây Ban Nha',
  'language.th': 'tiếng Thái',
  'language.uk': 'tiếng Ukraina',
  'language.vi': 'Tiếng Việt',
  'language.other': 'Khác',
  loadingAuthorizing: 'Ủy quyền',
  messageBubbleYouSentAt: 'Bạn đã gửi vào lúc',
  messageBubbleSentAt: '{{name}} được gửi vào lúc',
  messageBubbleMediaMessagesNotSupported:
    'Tin nhắn đa phương tiện không được hỗ trợ',
  messageBubbleRead: 'Đọc',
  messageInputInstructions: 'Nhập tin nhắn của bạn',
  messageInputSendMessage: 'Gửi tin nhắn',
  messageListAgent: 'Đại lý',
  messageListChatStarted: 'Cuộc trò chuyện đã bắt đầu',
  messageListIsTyping: '{{name}} đang gõ...',
  messageListLoading: 'Đang tải',
  messageListNew: 'Mới',
  messageListToday: 'Hôm nay',
  messageListYesterday: 'Hôm qua',
  preEngagementHiThere: 'Chào bạn!',
  preEngagementIntro:
    'Đây là trung tâm liên lạc Soluna và trò chuyện trên web về huấn luyện từ xa. Chúng tôi sẽ sẵn sàng trả lời câu hỏi của bạn hàng ngày',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro), từ 10 giờ sáng đến 10 giờ tối {{timezoneAbbrev}}.',
  preEngagementName: 'Tên',
  preEngagementNameInstructions: 'xin hãy nhập tên của bạn',
  preEngagementEmail: 'Địa chỉ email',
  preEngagementEmailInstructions: 'Hãy điền địa chỉ email của bạn',
  preEngagementLanguage: 'Ngôn ngữ',
  preEngagementHowCanWeHelp: 'Làm thế nào chúng tôi có thể giúp bạn?',
  preEngagementInstructions: 'Đặt một câu hỏi',
  preEngagementStartChat: 'Bắt đầu trò chuyện',
  preEngagementChatClosed: 'Trò chuyện đã đóng.',
  preEngagementChatClosedExplanation:
    'Rất tiếc, trung tâm liên hệ Soluna và trò chuyện trên web về huấn luyện từ xa hiện đã đóng cửa. Chúng tôi sẽ sẵn sàng trả lời câu hỏi của bạn hàng ngày',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation), từ 10 giờ sáng đến 10 giờ tối {{timezoneAbbrev}}.',
  transcriptDownload: 'Tải xuống',
  transcriptGeneratingTranscript: 'Đang tạo bản ghi...',
  transcriptDownloading: 'Đang tải xuống bản ghi...',
  transcriptSendToEmail: 'Gửi tới email của tôi',
  transcriptSending: 'Đang gửi bản ghi...',
  transcriptDoYouWantATranscript:
    'Bạn có muốn ghi lại cuộc trò chuyện của chúng tôi không?',
}
