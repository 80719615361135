// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const zh = {
  conversationEndedThanks: '谢谢您和我们聊天！',
  conversationEndedMoreQuestions: '如果您还有其他问题，请随时联系我们。',
  conversationEndedNewChat: '开始新的聊天',
  headerLiveChat: '在线聊天',
  'language.en': '英语',
  'language.ar': '阿拉伯语',
  'language.hy': '亚美尼亚语',
  'language.zh': '简体中文',
  'language.tl': '菲律宾语/他加禄语',
  'language.hi': '北印度语',
  'language.hmn': '苗族',
  'language.ja': '日语',
  'language.km': '高棉语',
  'language.ko': '韩语',
  'language.lo': '老挝',
  'language.fa': '波斯语/波斯语',
  'language.pa': '旁遮普语',
  'language.ru': '俄罗斯',
  'language.es': '西班牙语',
  'language.th': '泰语',
  'language.uk': '乌克兰语',
  'language.vi': '越南语',
  'language.other': '其他',
  loadingAuthorizing: '授权',
  messageBubbleYouSentAt: '您在',
  messageBubbleSentAt: '{{name}}在',
  messageBubbleMediaMessagesNotSupported: '不支持媒体信息',
  messageBubbleRead: '阅读',
  messageInputInstructions: '输入您的信息',
  messageInputSendMessage: '发送信息',
  messageListAgent: '代理',
  messageListChatStarted: '聊天开始',
  messageListIsTyping: '{{name}} 正在输入...',
  messageListLoading: '加载中',
  messageListNew: '新',
  messageListToday: '今天',
  messageListYesterday: '昨天',
  preEngagementHiThere: '你们好',
  preEngagementIntro:
    '这是 Soluna 联络中心和远程辅导网络聊天。 我们每天都会回答您的问题',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro)上午 10 点到晚上 10 点之间 {{timezoneAbbrev}}。',
  preEngagementName: '名称',
  preEngagementNameInstructions: '请输入您的姓名',
  preEngagementEmail: '电子邮件地址',
  preEngagementEmailInstructions: '请输入您的电子邮件地址',
  preEngagementLanguage: '语言',
  preEngagementHowCanWeHelp: '我们能为您提供什么帮助？',
  preEngagementInstructions: '提问',
  preEngagementStartChat: '开始聊天',
  preEngagementChatClosed: '聊天结束。',
  preEngagementChatClosedExplanation:
    '不幸的是，Soluna 联络中心和远程辅导网络聊天目前已关闭。 我们每天都会回答您的问题',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation)上午 10 点到晚上 10 点之间 {{timezoneAbbrev}}。',
  transcriptDownload: '下载',
  transcriptGeneratingTranscript: '生成成绩单...',
  transcriptDownloading: '下载成绩单...',
  transcriptSendToEmail: '发送到我的邮箱',
  transcriptSending: '发送成绩单...',
  transcriptDoYouWantATranscript: '你想要我们的聊天记录吗？',
}
