// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const es = {
  conversationEndedThanks: '¡Gracias por charlar con nosotros!',
  conversationEndedMoreQuestions:
    'Si tiene más preguntas, no dude en comunicarse nuevamente.',
  conversationEndedNewChat: 'Iniciar un nuevo chat',
  headerLiveChat: 'Chat en vivo',
  'language.en': 'Inglés',
  'language.ar': 'Árabe',
  'language.hy': 'Armenio',
  'language.zh': 'Chino (simplificado)',
  'language.tl': 'Filipino/tagalo',
  'language.hi': 'Hindi',
  'language.hmn': 'Hmong',
  'language.ja': 'Japonés',
  'language.km': 'Jemer',
  'language.ko': 'Coreano',
  'language.lo': 'Lao',
  'language.fa': 'Persa/Farsi',
  'language.pa': 'Punjabí',
  'language.ru': 'Ruso',
  'language.es': 'Español',
  'language.th': 'Tailandés',
  'language.uk': 'Ucraniano',
  'language.vi': 'Vietnamita',
  'language.other': 'Otros',
  loadingAuthorizing: 'Autorizar',
  messageBubbleYouSentAt: 'Enviaste a',
  messageBubbleSentAt: '{{name}} envía a',
  messageBubbleMediaMessagesNotSupported: 'No se admiten mensajes multimedia',
  messageBubbleRead: 'Leer',
  messageInputInstructions: 'Escribe tu mensaje',
  messageInputSendMessage: 'Enviar mensaje',
  messageListAgent: 'Agente',
  messageListChatStarted: 'Chat iniciado',
  messageListIsTyping: '{{name}} está escribiendo...',
  messageListLoading: 'Carga',
  messageListNew: 'NUEVO',
  messageListToday: 'Hoy',
  messageListYesterday: 'Ayer',
  preEngagementHiThere: '¡Hola!',
  preEngagementIntro:
    'Este es el centro de contacto de Soluna y el chat web de telecoaching. Estaremos disponibles para responder a sus preguntas todos los días',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro), entre las 10 y las 22 horas {{timezoneAbbrev}}.',
  preEngagementName: 'Nombre',
  preEngagementNameInstructions: 'Por favor, introduzca su nombre',
  preEngagementEmail: 'Dirección de correo electrónico',
  preEngagementEmailInstructions:
    'Por favor, introduzca su dirección de correo electrónico',
  preEngagementLanguage: 'Idioma',
  preEngagementHowCanWeHelp: '¿Cómo podemos ayudarte?',
  preEngagementInstructions: 'Hacer una pregunta',
  preEngagementStartChat: 'Iniciar chat',
  preEngagementChatClosed: 'Chat cerrado.',
  preEngagementChatClosedExplanation:
    'Desafortunadamente, el centro de contacto de Soluna y el chat web de telecoaching están actualmente cerrados. Estaremos disponibles para responder a sus preguntas todos los días',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation), entre las 10 a.m. y las 10 p.m. {{timezoneAbbrev}}.',
  transcriptDownload: 'Descargar',
  transcriptGeneratingTranscript: 'Generación de transcripción...',
  transcriptDownloading: 'Descargando la transcripción...',
  transcriptSendToEmail: 'Enviar a mi correo electrónico',
  transcriptSending: 'Envío de transcripción...',
  transcriptDoYouWantATranscript:
    '¿Quieres una transcripción de nuestra charla?',
}
