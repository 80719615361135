import { Message } from '@twilio/conversations'
import { Box } from '@twilio-paste/core/box'
import { Text } from '@twilio-paste/core/text'

import { getDaysOld } from '../utils/getDaysOld'
import {
  separatorContainerStyles,
  getSeparatorLineStyles,
  getSeparatorTextStyles,
} from './styles/MessageListSeparator.styles'
import { SeparatorType } from './definitions'
import { useSafeTranslation } from '../i18n/useSafeTranslation'

export const MessageListSeparator = ({
  message,
  separatorType,
}: {
  message: Message
  separatorType: SeparatorType
}) => {
  const t = useSafeTranslation()
  const getSeparatorText = () => {
    let separatorText
    if (separatorType === 'new') {
      separatorText = t('messageListNew')
    } else {
      const daysOld = getDaysOld(message.dateCreated)
      if (daysOld === 0) {
        separatorText = t('messageListToday')
      } else if (daysOld === 1) {
        separatorText = t('messageListYesterday')
      } else {
        separatorText = message.dateCreated.toLocaleDateString()
      }
    }

    return separatorText
  }

  return (
    <Box
      {...separatorContainerStyles}
      data-test="new-message-separator"
      role="separator"
    >
      <Box {...getSeparatorLineStyles(separatorType)} aria-hidden="true" />
      <Text as="p" {...getSeparatorTextStyles(separatorType)}>
        {getSeparatorText()}
      </Text>
      <Box {...getSeparatorLineStyles(separatorType)} aria-hidden="true" />
    </Box>
  )
}
