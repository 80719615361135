// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const hmn = {
  conversationEndedThanks: 'Ua tsaug rau kev sib tham nrog peb!',
  conversationEndedMoreQuestions:
    'Yog tias koj muaj lus nug ntxiv, thov hu rau peb ntxiv.',
  conversationEndedNewChat: 'Pib sib tham tshiab',
  headerLiveChat: 'Tham nyob',
  'language.en': 'Lus Askiv',
  'language.ar': 'Arabic',
  'language.hy': 'Armenian',
  'language.zh': 'Suav (Simplified)',
  'language.tl': 'Filipino/Tagalog',
  'language.hi': 'Hindi',
  'language.hmn': 'Hmoob',
  'language.ja': 'Nyiv',
  'language.km': 'Khmer',
  'language.ko': 'Kauslim',
  'language.lo': 'Nplog',
  'language.fa': 'Persian/Farsi',
  'language.pa': 'Punjabi',
  'language.ru': 'Lavxias',
  'language.es': 'Spanish',
  'language.th': 'Thaib',
  'language.uk': 'Ukranian',
  'language.vi': 'Nyab Laj',
  'language.other': 'Lwm yam',
  loadingAuthorizing: 'Tso cai',
  messageBubbleYouSentAt: 'Koj xa ntawm',
  messageBubbleSentAt: '{{name}} xa ntawm',
  messageBubbleMediaMessagesNotSupported: 'Media lus tsis txaus siab',
  messageBubbleRead: 'Nyeem',
  messageInputInstructions: 'Ntaus koj cov lus',
  messageInputSendMessage: 'Xa lus',
  messageListAgent: 'Tus neeg saib xyuas',
  messageListChatStarted: 'Sib tham pib',
  messageListIsTyping: '{{name}} yog typing...',
  messageListLoading: 'Chaw thau khoom',
  messageListNew: 'Tshiab',
  messageListToday: 'Hnub no',
  messageListYesterday: 'Nag hmo',
  preEngagementHiThere: 'Nyob zoo muaj!',
  preEngagementIntro:
    'Qhov no yog Soluna chaw hu xov tooj thiab telecoaching web-chat. Peb yuav muaj los teb koj cov lus nug txhua hnub',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro), thaum 10 teev sawv ntxov txog 10 teev tsaus ntuj {{timezoneAbbrev}}.',
  preEngagementName: 'Lub npe',
  preEngagementNameInstructions: 'Thov sau koj lub npe',
  preEngagementEmail: 'Email Chaw Nyob',
  preEngagementEmailInstructions: 'Thov sau koj tus email chaw nyob',
  preEngagementLanguage: 'Lus',
  preEngagementHowCanWeHelp: 'Peb tuaj yeem pab koj li cas?',
  preEngagementInstructions: 'Nug ib lo lus nug',
  preEngagementStartChat: 'Pib sib tham',
  preEngagementChatClosed: 'Sib tham kaw.',
  preEngagementChatClosedExplanation:
    'Hmoov tsis zoo, lub chaw hu xov tooj Soluna thiab telecoaching web-chat tam sim no raug kaw. Peb yuav muaj los teb koj cov lus nug txhua hnub',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation), thaum 10 teev sawv ntxov txog 10 teev tsaus ntuj {{timezoneAbbrev}}.',
  transcriptDownload: 'Download tau',
  transcriptGeneratingTranscript: 'Generating transcript...',
  transcriptDownloading: 'Downloading transcript...',
  transcriptSendToEmail: 'Xa rau kuv tus email',
  transcriptSending: 'Xa cov ntawv sau ...',
  transcriptDoYouWantATranscript:
    'Koj puas xav tau daim ntawv teev lus ntawm peb cov lus sib tham?',
}
