import { createRoot } from 'react-dom/client'
import { App } from './App'
import { withTranslations } from './i18n'

const rootElement = document.getElementById(
  'soluna-webchat-widget-root'
) as HTMLElement
const root = createRoot(rootElement)

root.render(withTranslations(<App />))
