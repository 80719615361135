import { Notification } from './store/definitions'
import { getDaylightSavingsAwareTz } from './utils/time'

const noConnectionNotification = (): Notification => ({
  id: 'NoConnectionNotification',
  dismissible: true,
  message: 'Connection lost. Attempting to reconnect.',
  type: 'warning',
})

const failedToInitSessionNotification = (error: string): Notification => ({
  id: `FailedToInitSessionNotification`,
  dismissible: true,
  message: `Something went wrong. ${error}. Please try again later.`,
  type: 'error',
})

const webchatNotOpenNotification = (): Notification => {
  // TODO - Notifications to users who are webchatting in a language other than English
  const californiaTimeZone = getDaylightSavingsAwareTz({
    timeZone: 'America/Los_Angeles',
  })
  return {
    id: `WebchatNotOpenNotification`,
    dismissible: true,
    message: `Currently not accepting new chats. Please try contacting again between 10am and 10pm ${californiaTimeZone}`,
    type: 'error',
  }
}

export const notifications = {
  noConnectionNotification,
  failedToInitSessionNotification,
  webchatNotOpenNotification,
}
