// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const hy = {
  conversationEndedThanks: 'Շնորհակալություն մեզ հետ զրուցելու համար:',
  conversationEndedMoreQuestions:
    'Եթե դուք ունեք այլ հարցեր, ազատ զգալ դիմեք կրկին.',
  conversationEndedNewChat: 'Սկսեք նոր զրույց',
  headerLiveChat: 'Ուղիղ զրույց',
  'language.en': 'Անգլերեն',
  'language.ar': 'արաբերեն',
  'language.hy': 'հայերեն',
  'language.zh': 'չինարեն (պարզեցված)',
  'language.tl': 'Ֆիլիպիներեն/Տագալոգ',
  'language.hi': 'հինդի',
  'language.hmn': 'Հմոնգ',
  'language.ja': 'ճապոներեն',
  'language.km': 'քմեր',
  'language.ko': 'կորեերեն',
  'language.lo': 'Լաոս',
  'language.fa': 'պարսկերեն/ֆարսի',
  'language.pa': 'փենջաբերեն',
  'language.ru': 'ռուսերեն',
  'language.es': 'իսպաներեն',
  'language.th': 'Թայերեն',
  'language.uk': 'ուկրաինական',
  'language.vi': 'Վիետնամերեն',
  'language.other': 'Այլ',
  loadingAuthorizing: 'Լիազորող',
  messageBubbleYouSentAt: 'Դուք ուղարկել եք ժ',
  messageBubbleSentAt: '{{name}} ուղարկվել է',
  messageBubbleMediaMessagesNotSupported:
    'Մեդիա հաղորդագրությունները չեն աջակցվում',
  messageBubbleRead: 'Կարդացեք',
  messageInputInstructions: 'Մուտքագրեք ձեր հաղորդագրությունը',
  messageInputSendMessage: 'Ուղարկել հաղորդագրություն',
  messageListAgent: 'Գործակալ',
  messageListChatStarted: 'Զրույցը սկսվեց',
  messageListIsTyping: '{{name}} գրում է...',
  messageListLoading: 'Բեռնվում է',
  messageListNew: 'Նոր',
  messageListToday: 'Այսօր',
  messageListYesterday: 'Երեկ',
  preEngagementHiThere: 'Ողջույն!',
  preEngagementIntro:
    'Սա Soluna կոնտակտային կենտրոնն է և հեռաուսուցման վեբ-չաթը: Մենք ամեն օր պատրաստ կլինենք պատասխանել ձեր հարցերին',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro), ժամը 10-ից 22-ը {{timezoneAbbrev}}:',
  preEngagementName: 'Անուն',
  preEngagementNameInstructions: 'Խնդրում ենք մուտքագրել ձեր անունը',
  preEngagementEmail: 'Էլեկտրոնային հասցե',
  preEngagementEmailInstructions: 'Խնդրում ենք մուտքագրել ձեր էլփոստի հասցեն',
  preEngagementLanguage: 'Լեզու',
  preEngagementHowCanWeHelp: 'Ինչպե՞ս կարող ենք օգնել ձեզ:',
  preEngagementInstructions: 'Հարց տվեք',
  preEngagementStartChat: 'Սկսեք զրուցել',
  preEngagementChatClosed: 'Զրույցը փակվեց։',
  preEngagementChatClosedExplanation:
    'Ցավոք, Soluna-ի կոնտակտային կենտրոնը և հեռաուսուցման վեբ-չաթը ներկայումս փակ են: Մենք ամեն օր պատրաստ կլինենք պատասխանել ձեր հարցերին',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation), ժամը 10-ից 22-ը {{timezoneAbbrev}}:',
  transcriptDownload: 'Բեռնել',
  transcriptGeneratingTranscript: 'Տառադարձման ստեղծում...',
  transcriptDownloading: 'Տառադարձումը ներբեռնվում է...',
  transcriptSendToEmail: 'Ուղարկել իմ էլ',
  transcriptSending: 'Տառադարձումն ուղարկվում է...',
  transcriptDoYouWantATranscript: 'Ցանկանու՞մ եք մեր զրույցի սղագրությունը:',
}
