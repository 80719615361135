// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const km = {
  conversationEndedThanks: 'អរគុណសម្រាប់ការជជែកជាមួយពួកយើង!',
  conversationEndedMoreQuestions:
    'ប្រសិនបើអ្នកមានចម្ងល់បន្ថែម សូមទាក់ទងមកម្តងទៀត។',
  conversationEndedNewChat: 'ចាប់ផ្តើមការជជែកថ្មី។',
  headerLiveChat: 'ជជែកផ្ទាល់',
  'language.en': 'ភាសាអង់គ្លេស',
  'language.ar': 'ភាសាអារ៉ាប់',
  'language.hy': 'អាមេនី',
  'language.zh': 'ភាសាចិន (សាមញ្ញ)',
  'language.tl': 'ហ្វីលីពីន/តាហ្គាឡុក',
  'language.hi': 'ហិណ្ឌូ',
  'language.hmn': 'ជនជាតិខ្មែរ',
  'language.ja': 'ជប៉ុន',
  'language.km': 'ខ្មែរ',
  'language.ko': 'កូរ៉េ',
  'language.lo': 'ឡាវ',
  'language.fa': 'Persian/Farsi',
  'language.pa': 'ពុនចាប៊ី',
  'language.ru': 'រុស្សី',
  'language.es': 'ភាសាអេស្ប៉ាញ',
  'language.th': 'ថៃ',
  'language.uk': 'អ៊ុយក្រែន',
  'language.vi': 'វៀតណាម',
  'language.other': 'ផ្សេងទៀត',
  loadingAuthorizing: 'ការអនុញ្ញាត',
  messageBubbleYouSentAt: 'អ្នកបានផ្ញើនៅ',
  messageBubbleSentAt: '{{name}} បានផ្ញើនៅ',
  messageBubbleMediaMessagesNotSupported:
    'សារប្រព័ន្ធផ្សព្វផ្សាយមិនត្រូវបានគាំទ្រទេ។',
  messageBubbleRead: 'អាន',
  messageInputInstructions: 'វាយសាររបស់អ្នក។',
  messageInputSendMessage: 'ផ្ញើ​សារ',
  messageListAgent: 'ភ្នាក់ងារ',
  messageListChatStarted: 'ការជជែកបានចាប់ផ្តើម',
  messageListIsTyping: '{{name}} កំពុង​វាយ...',
  messageListLoading: 'កំពុងផ្ទុក',
  messageListNew: 'ថ្មី។',
  messageListToday: 'ថ្ងៃនេះ',
  messageListYesterday: 'ម្សិលមិញ',
  preEngagementHiThere: 'សួស្តី!',
  preEngagementIntro:
    'នេះគឺជាមជ្ឈមណ្ឌលទំនាក់ទំនង Soluna និង telecoaching web-chat ។ យើងនឹងអាចឆ្លើយសំណួររបស់អ្នកជារៀងរាល់ថ្ងៃ',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro) ចន្លោះម៉ោង 10 ព្រឹកដល់ម៉ោង 10 យប់ {{timezoneAbbrev}} ។',
  preEngagementName: 'ឈ្មោះ',
  preEngagementNameInstructions: 'សូម​បញ្ចូល​ឈ្មោះ​របស់​អ្នក',
  preEngagementEmail: 'អាស័យ​ដ្ឋាន​អ៊ី​ម៉េ​ល',
  preEngagementEmailInstructions: 'សូមបញ្ចូលអាសយដ្ឋានអ៊ីមែលរបស់អ្នក។',
  preEngagementLanguage: 'ភាសា',
  preEngagementHowCanWeHelp: 'តើ​យើង​អាច​ជួយ​អ្នក​ដោយ​របៀបណា​?',
  preEngagementInstructions: 'សួរ​សំណួរ​មួយ',
  preEngagementStartChat: 'ចាប់ផ្តើមជជែក',
  preEngagementChatClosed: 'ការជជែកត្រូវបានបិទ។',
  preEngagementChatClosedExplanation:
    'ជាអកុសល មជ្ឈមណ្ឌលទំនាក់ទំនង Soluna និងការជជែកតាមអ៊ីនធឺណិតត្រូវបានបិទ។ យើងនឹងអាចឆ្លើយសំណួររបស់អ្នកជារៀងរាល់ថ្ងៃ',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation) ចន្លោះម៉ោង 10 ព្រឹកដល់ម៉ោង 10 យប់ {{timezoneAbbrev}} ។',
  transcriptDownload: 'ទាញយក',
  transcriptGeneratingTranscript: 'កំពុងបង្កើតប្រតិចារិក...',
  transcriptDownloading: 'កំពុងទាញយកប្រតិចារិក...',
  transcriptSendToEmail: 'ផ្ញើទៅអ៊ីមែលរបស់ខ្ញុំ',
  transcriptSending: 'កំពុងផ្ញើប្រតិចារិក...',
  transcriptDoYouWantATranscript: 'តើអ្នកចង់បានប្រតិចារិកនៃការជជែករបស់យើងទេ?',
}
