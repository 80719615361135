// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const lo = {
  conversationEndedThanks: 'ຂອບໃຈສໍາລັບການສົນທະນາກັບພວກເຮົາ!',
  conversationEndedMoreQuestions:
    'ຖ້າທ່ານມີຄຳຖາມເພີ່ມເຕີມ, ກະລຸນາຕິດຕໍ່ຫາອີກຄັ້ງ.',
  conversationEndedNewChat: 'ເລີ່ມການສົນທະນາໃໝ່',
  headerLiveChat: 'ສົນທະນາສົດ',
  'language.en': 'ພາສາອັງກິດ',
  'language.ar': 'ອາຣັບ',
  'language.hy': 'ອາເມເນຍ',
  'language.zh': 'ຈີນ (ຕົວຫຍໍ້)',
  'language.tl': 'ຟີລິບປິນ/Tagalog',
  'language.hi': 'ຮິນດູ',
  'language.hmn': 'ມົ້ງ',
  'language.ja': 'ພາສາຍີ່ປຸ່ນ',
  'language.km': 'ຂະເໝນ',
  'language.ko': 'ເກົາຫຼີ',
  'language.lo': 'ພາສາລາວ',
  'language.fa': 'ເປີເຊຍ/Farsi',
  'language.pa': 'ປັນຈາບີ',
  'language.ru': 'ພາສາລັດເຊຍ',
  'language.es': 'ແອສປາໂຍນ',
  'language.th': 'ໄທ',
  'language.uk': 'ອູແກຣນ',
  'language.vi': 'ຫວຽດນາມ',
  'language.other': 'ອື່ນໆ',
  loadingAuthorizing: 'ການອະນຸຍາດ',
  messageBubbleYouSentAt: 'ທ່ານສົ່ງຢູ່ທີ່',
  messageBubbleSentAt: '{{name}} ສົ່ງເມື່ອ',
  messageBubbleMediaMessagesNotSupported: 'ບໍ່ຮອງຮັບຂໍ້ຄວາມສື່',
  messageBubbleRead: 'ອ່ານ',
  messageInputInstructions: 'ພິມຂໍ້ຄວາມຂອງທ່ານ',
  messageInputSendMessage: 'ສົ່ງ​ຂໍ້​ຄວາມ',
  messageListAgent: 'ຕົວແທນ',
  messageListChatStarted: 'ເລີ່ມການສົນທະນາແລ້ວ',
  messageListIsTyping: '{{name}} ກຳລັງພິມ...',
  messageListLoading: 'ກຳລັງໂຫຼດ',
  messageListNew: 'ໃຫມ່',
  messageListToday: 'ມື້​ນີ້',
  messageListYesterday: 'ມື້ວານນີ້',
  preEngagementHiThere: 'ສະ​ບາຍ​ດີ!',
  preEngagementIntro:
    'ນີ້​ແມ່ນ​ສູນ​ຕິດ​ຕໍ່ Soluna ແລະ​ການ​ສົນ​ທະ​ນາ​ເວັບ​ໄຊ​ຕ​໌​. ພວກເຮົາຈະພ້ອມຕອບຄຳຖາມຂອງເຈົ້າທຸກໆມື້',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro), ລະຫວ່າງ 10 ໂມງເຊົ້າຫາ 10 ໂມງແລງ {{timezoneAbbrev}}.',
  preEngagementName: 'ຊື່',
  preEngagementNameInstructions: 'ກະລຸນາໃສ່ຊື່ຂອງທ່ານ',
  preEngagementEmail: 'ທີ່​ຢູ່​ອີ​ເມວ',
  preEngagementEmailInstructions: 'ກະລຸນາໃສ່ທີ່ຢູ່ອີເມວຂອງທ່ານ',
  preEngagementLanguage: 'ພາສາ',
  preEngagementHowCanWeHelp: 'ພວກເຮົາຈະຊ່ວຍເຈົ້າໄດ້ແນວໃດ?',
  preEngagementInstructions: 'ຖາມ​ຄໍາ​ຖາມ',
  preEngagementStartChat: 'ເລີ່ມການສົນທະນາ',
  preEngagementChatClosed: 'ປິດການສົນທະນາແລ້ວ.',
  preEngagementChatClosedExplanation:
    'ໂຊກບໍ່ດີທີ່ສູນຕິດຕໍ່ Soluna ແລະ web-chating telecoaching ຖືກປິດ. ພວກເຮົາຈະພ້ອມຕອບຄຳຖາມຂອງເຈົ້າທຸກໆມື້',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation), ລະຫວ່າງ 10 ໂມງເຊົ້າຫາ 10 ໂມງແລງ {{timezoneAbbrev}}.',
  transcriptDownload: 'ດາວໂຫຼດ',
  transcriptGeneratingTranscript: 'ກຳລັງສ້າງການຖອດຂໍ້ຄວາມ...',
  transcriptDownloading: 'ກຳລັງດາວໂຫຼດການຖອດຂໍ້ຄວາມ...',
  transcriptSendToEmail: 'ສົ່ງໄປທີ່ອີເມວຂອງຂ້ອຍ',
  transcriptSending: 'ກຳລັງສົ່ງການຖອດຂໍ້ຄວາມ...',
  transcriptDoYouWantATranscript:
    'ທ່ານຕ້ອງການຖອດຂໍ້ຄວາມຈາກການສົນທະນາຂອງພວກເຮົາບໍ?',
}
