// THIS FILE WAS AUTO-IMPORTED ON 3/8/2024.
// ANY EDITS MADE TO IT DIRECTLY WILL BE
// OVERWRITTEN THE NEXT TIME TRANSLATIONS ARE IMPORTED.
// TO MAKE CHANGES, EDIT en.ts, THEN FOLLOW INSTRUCTIONS
// FOUND IN THE README

export const th = {
  conversationEndedThanks: 'ขอบคุณสำหรับการสนทนากับเรา!',
  conversationEndedMoreQuestions: 'หากคุณมีคำถามเพิ่มเติม โปรดติดต่ออีกครั้ง',
  conversationEndedNewChat: 'เริ่มแชทใหม่',
  headerLiveChat: 'แชทสด',
  'language.en': 'ภาษาอังกฤษ',
  'language.ar': 'ภาษาอาหรับ',
  'language.hy': 'อาร์เมเนีย',
  'language.zh': 'จีน (ตัวย่อ)',
  'language.tl': 'ฟิลิปปินส์/ตากาล็อก',
  'language.hi': 'ฮินดี',
  'language.hmn': 'ม้ง',
  'language.ja': 'ญี่ปุ่น',
  'language.km': 'เขมร',
  'language.ko': 'เกาหลี',
  'language.lo': 'ลาว',
  'language.fa': 'เปอร์เซีย/ฟาร์ซี',
  'language.pa': 'ปัญจาบ',
  'language.ru': 'ภาษารัสเซีย',
  'language.es': 'สเปน',
  'language.th': 'แบบไทย',
  'language.uk': 'ภาษายูเครน',
  'language.vi': 'ภาษาเวียดนาม',
  'language.other': 'อื่น',
  loadingAuthorizing: 'การอนุญาต',
  messageBubbleYouSentAt: 'คุณส่งมาที่',
  messageBubbleSentAt: '{{name}} ส่งไปที่',
  messageBubbleMediaMessagesNotSupported: 'ไม่รองรับข้อความมีเดีย',
  messageBubbleRead: 'อ่าน',
  messageInputInstructions: 'พิมพ์ข้อความของคุณ',
  messageInputSendMessage: 'ส่งข้อความ',
  messageListAgent: 'ตัวแทน',
  messageListChatStarted: 'เริ่มแชทแล้ว',
  messageListIsTyping: '{{name}} กำลังพิมพ์...',
  messageListLoading: 'กำลังโหลด',
  messageListNew: 'ใหม่',
  messageListToday: 'วันนี้',
  messageListYesterday: 'เมื่อวาน',
  preEngagementHiThere: 'สวัสดี!',
  preEngagementIntro:
    'นี่คือศูนย์ติดต่อของ Soluna และการสนทนาทางเว็บสำหรับการฝึกสอนทางไกล เราจะพร้อมตอบคำถามของคุณทุกวัน',
  preEngagementIntroWithHours:
    '$t(preEngagementIntro) ระหว่างเวลา 10.00 น. ถึง 22.00 น. {{timezoneAbbrev}}',
  preEngagementName: 'ชื่อ',
  preEngagementNameInstructions: 'กรุณากรอกชื่อของคุณ',
  preEngagementEmail: 'ที่อยู่อีเมล',
  preEngagementEmailInstructions: 'กรุณากรอกอีเมลของคุณ',
  preEngagementLanguage: 'ภาษา',
  preEngagementHowCanWeHelp: 'เราจะช่วยคุณได้อย่างไร?',
  preEngagementInstructions: 'ถามคำถาม',
  preEngagementStartChat: 'เริ่มแชท',
  preEngagementChatClosed: 'ปิดแชทแล้ว',
  preEngagementChatClosedExplanation:
    'ขออภัย ศูนย์ติดต่อของ Soluna และการสนทนาทางเว็บทางไกลปิดอยู่ในขณะนี้ เราจะพร้อมตอบคำถามของคุณทุกวัน',
  preEngagementChatClosedExplanationWithHours:
    '$t(preEngagementChatClosedExplanation) ระหว่างเวลา 10.00 น. ถึง 22.00 น. {{timezoneAbbrev}}',
  transcriptDownload: 'ดาวน์โหลด',
  transcriptGeneratingTranscript: 'กำลังสร้างข้อความถอดเสียง...',
  transcriptDownloading: 'กำลังดาวน์โหลดข้อความถอดเสียง...',
  transcriptSendToEmail: 'ส่งไปที่อีเมลของฉัน',
  transcriptSending: 'กำลังส่งข้อความถอดเสียง...',
  transcriptDoYouWantATranscript: 'คุณต้องการสำเนาการแชทของเราหรือไม่?',
}
