import { Box } from '@twilio-paste/core/box'
import { Text } from '@twilio-paste/core/text'

import { containerStyles, titleStyles } from './styles/Header.styles'
import { useSafeTranslation } from '../i18n/useSafeTranslation'

export const Header = ({ customTitle }: { customTitle?: string }) => {
  const t = useSafeTranslation()
  return (
    <Box as="header" {...containerStyles}>
      <Text as="h2" {...titleStyles}>
        {customTitle ?? t('headerLiveChat')}
      </Text>
    </Box>
  )
}
